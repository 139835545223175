import React, {useEffect,useContext} from "react";
import InnerProjet from './InnerProjet';
import Queries from 'utils/Queries';
import { useQuery } from '@apollo/client';
import { useParams } from 'react-router-dom';
import './Projet.scss'
import { SettingsContext } from 'Providers/SettingsProvider';

function Projet({catalogue=false}) {
  const { appState } = useContext(SettingsContext);
  const {slug} = useParams();
  const { data,refetch } = useQuery(Queries.projet,{variables:{
    slug,
    catalogue:appState.defaultBbpv,
  }});
  useEffect(()=>{
    refetch();
  },[refetch]);
  const projet=data && data.projets && data.projets[0] ? data.projets[0] : {};
  const liste=data ? (catalogue ? data.listeCatalogue : data.listeUne) : [];
  return <InnerProjet projet={projet} liste={liste} catalogue={catalogue}/>;
}
export default Projet;
