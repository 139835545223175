import React, { useContext } from "react";
import { SettingsContext } from 'Providers/SettingsProvider';
import Newsletter from 'components/Newsletter';
import {ReactComponent as Croix} from 'assets/croix.svg';

import './NewsletterDrawer.scss'

function NewsletterDrawer() {
  const { appState, setAppState } = useContext(SettingsContext);
	const close=()=>{
		setAppState(state=>{return { ...state, newsOpen:false}});
	}
  const { newsOpen } = appState;
  return <div className={'newsletter-drawer'+(newsOpen ? ' open' : '')}>
    <div className="newsletter-drawer-inner">
    	<Newsletter/>
			<Croix onClick={close}/>
    </div>
  </div>
  ;
}
export default NewsletterDrawer;
