import React, { useState, useEffect, useMemo } from "react";
import InnerProjet from 'components/Projet/InnerProjet';
import Queries from 'utils/Queries';
import { useQuery } from '@apollo/client';
import './Preview.scss';

function Preview() {
  const [ values, setValues ] = useState({});
  const handleMessage=(msg)=>{
      if (msg.data.type && msg.data.type==='preview'){
        setValues(msg.data.v);
      }
  }
  useEffect(()=>{
    window.addEventListener('message', handleMessage);
    if (window.parent) window.parent.postMessage("ok", "*");
    return ()=>window.removeEventListener('message', handleMessage);
  },[]);
  const { data,refetch } = useQuery(Queries.projetPreview,{variables:{
    id:values.id || 0,
  }});
  useEffect(()=>{
    refetch();
  },[refetch,values]);
  const projet=useMemo(()=>data && data.projets && data.projets[0] ? data.projets[0] : {},[data]);
  const actualProjet=useMemo(()=>{
    const fields=['auteur','catalogue','date','visuel'];
    const relations=['translations','diaporama'];
    const res={};
    for (const field of fields) {
      res[field]= values[field] || projet[field] || null;
    }
    for (const relation of relations) {
      if (projet[relation]) {
        res[relation]=[...projet[relation]];
        if (values[relation] && values[relation].update) {
          for (const item of values[relation].update) {
            const idx=res[relation].findIndex(o=>parseInt(o.id)===item.id)
            res[relation][idx]={...projet[relation][idx],...item};
          }
        }
        if (values[relation] && values[relation].create) {
          for (const item of values[relation].create) {
            res[relation].push(item);
          }
        }
        if (values[relation] && values[relation].delete) {
          for (const id of values[relation].delete) {
            const idx=res[relation].findIndex(o=>parseInt(o.id)===id)
            res[relation].splice(idx,1);
          }
        }
      }
    }
    return res;
  },[projet,values]);
  return <InnerProjet projet={actualProjet}/>;
}
export default Preview;
