import React, { useContext } from "react";
import './LangSwitch.scss';
import { SettingsContext } from 'Providers/SettingsProvider';

function LangSwitch({align}) {
  const { lang, langs, setAppState } = useContext(SettingsContext);
  const handleToggleLang=()=>{
    const idx=langs.findIndex(o=>o.code===lang);
    const newIdx=(idx+1)%langs.length;
    setAppState(state=>{return {...state,lang:langs[newIdx].code}});
  }
  const l=langs.find(o=>o.code===lang);
  return langs ? <div className="lang-switch" onClick={handleToggleLang}>
    <div className="globe-icon">
      <svg x="0px" y="0px" viewBox="0 0 20 20">
      <circle cx="9.9" cy="10" r="9" strokeWidth="1.5" fill="none"/>
      <g>
      	<path d="M7.5,1.4c-1.7,6.7-1.7,10.5,0,17.2" strokeWidth="1.5" fill="none"/>
      	<path d="M12.2,18.6c1.7-6.7,1.7-10.5,0-17.2" strokeWidth="1.5" fill="none"/>
      </g>
      <path d="M18.7,10H1.1" strokeWidth="1.5" fill="none"/>
      </svg>
    </div>
    <span>{l && l.label}</span>
  </div> : '';
}

export default LangSwitch;
