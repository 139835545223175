import React, { useContext, useMemo, useCallback, useRef, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { SettingsContext } from 'Providers/SettingsProvider';
import Utils from 'utils/Utils';

import './Drawer.scss'
const icon=<svg version="1.1" width="20" height="20" viewBox="0 0 28 28">
	<path d="M5.3,22.8L22.8,5.3" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"/>
	<path d="M6.7,5.1h15.9" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"/>
	<path d="M22.8,21.2V5.3" stroke="#ffffff" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round" strokeMiterlimit="10"/>
</svg>;

function Drawer() {
  const { t, appState, setAppState, nb } = useContext(SettingsContext);
  const toggleNews=useCallback(()=>{
    setAppState(state=>{return{...state,newsOpen:!state.newsOpen}});
  },[setAppState]);
	const drawerRef=useRef(null);
  const replacedMenu=useMemo(()=>{
    let res=t('menu','html');
    res=res.replace(/#contact/g,'<a id="contact"></a>');
    res=res.replace(/#mentions/g,'<a id="mentions"></a>');
    res=res.replace(/#equipe/g,'<a id="equipe"></a>');
    res=res.replace(/#catalogue/g,'<a id="catalogue"></a>');
    res=res.replace(/#newsletter/g,'<a id="newsletter"></a>');
    res=res.replace(/#nb/g,nb);
    return res;
  },[t,nb]);
  const replacements=useMemo(()=>[
    {id:'contact',replacement:<NavLink to={'/contact'} className='menu-chip'>{t('menu-contact')} {icon}</NavLink>},
    {id:'equipe',replacement:<NavLink to={'/equipe'} className='menu-chip'>{t('menu-equipe')} {icon}</NavLink>},
    {id:'mentions',replacement:<NavLink to={'/mentions'} className='menu-chip'>{t('menu-mentions')} {icon}</NavLink>},
    {id:'catalogue',replacement:<NavLink to={'/catalogue'} className='menu-chip'>{t('menu-catalogue')} {icon}</NavLink>},
    {id:'newsletter',replacement:<button onClick={toggleNews} className='menu-chip'>{t('menu-newsletter')} {icon}</button>},
  ],[t,toggleNews]);
  const { menuOpen } = appState;
	useEffect(()=>{
		if(menuOpen) drawerRef.current.scrollTop=0;
	},[menuOpen]);
  return <div className={'drawer'+(menuOpen ? ' open' : '')} ref={drawerRef}>
    <div className="titre">
      {t('menu')}
    </div>
    <div className="texte">
      {Utils.parseWithLinks(replacedMenu,replacements)}
    </div>
  </div>
  ;
}
export default Drawer;
