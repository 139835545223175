import React, {useContext, useState, useLayoutEffect} from "react";
import Cercle from 'components/Cercle';
import Logo from 'components/Logo';
import TitreAnime from 'components/TitreAnime';
import { SettingsContext } from 'Providers/SettingsProvider';
import './Equipe.scss'
import Utils from 'utils/Utils';

function InnerEquipe({equipe}) {
  const [ loaded, setLoaded ] = useState(false);
  const { translate,appState } = useContext(SettingsContext);
  useLayoutEffect(()=>{
    const timer=setTimeout(()=>setLoaded(true),100);
    return ()=>clearTimeout(timer);
  },[setLoaded]);
  const { defaultBbpv } = appState;
  return <div className={'equipe'+(loaded ? ' loaded':'')}>
    <div className='main-header'>
      <Cercle color={true}/>
      <Logo from={defaultBbpv}/>
    </div>
    <div className="equipe-body">
        <div className="titre">
          <TitreAnime titre={translate(equipe,'titre')}/>
        </div>
        <div className="texte">
          {Utils.parseWithLinks(translate(equipe,'texte'))}
        </div>
    </div>
    <div className="spacer"></div>
  </div>;
}
export default InnerEquipe;
