import React, {useEffect} from "react";
import InnerContact from './InnerContact';
import Queries from 'utils/Queries';
import { useQuery } from '@apollo/client';
import './Contact.scss'

function Contact() {
  const { data,refetch } = useQuery(Queries.contact);
  useEffect(()=>{
    refetch();
  },[refetch]);
  const contact=data && data.contact ? data.contact : {};
  return <InnerContact contact={contact}/>;
}
export default Contact;
