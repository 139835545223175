import React, {useState, useLayoutEffect, useMemo} from "react";
import './TitreAnime.scss'

function TitreAnime({titre}) {
  const [ loaded, setLoaded ] = useState(false);
  const mots=useMemo(()=>titre ? titre.split(' ') :[],[titre]);
  useLayoutEffect(()=>{
    if (mots.length>0) {
      const timer=setTimeout(()=>setLoaded(true),100);
      return ()=>clearTimeout(timer);
    }
  },[setLoaded,mots]);
  return <div className={'titre-anime'+(loaded ? ' loaded' : '')}>
    {mots.map((mot,i)=><span key={mot+i} className='mot'>
      {i===mots.length-1 ? mot : mot+' '}
    </span>)}
  </div>;
}
export default TitreAnime;
