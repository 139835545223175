import React, { useContext } from "react";
import './Footer.scss'
import Logo from 'components/Logo';
import Newsletter from 'components/Newsletter';
import { SettingsContext } from 'Providers/SettingsProvider';
import Utils from 'utils/Utils';
import {ReactComponent as Insta} from './insta.svg';
import {ReactComponent as Fb} from './fb.svg';
import {ReactComponent as Suivant} from 'assets/suivant.svg';
import { NavLink } from "react-router-dom";
import Conf from 'utils/Conf';

function Footer() {
  const { t, appState,lang, screenSize } = useContext(SettingsContext);
  const { defaultBbpv } = appState;
  return <div className='footer'>
    {defaultBbpv==='bb' ? <>
      <div className='pv active'>
        <a href={`${Conf.urlsPv[0]}?lang=${lang}`}><Logo from='pv' variant={defaultBbpv==='bb'?'blanc':'blanc'} link={false}/>
        <div className='desc'>{Utils.parseWithLinks(t('footer-pv','html'))}</div>
        <Suivant className='svg-color-pv'/></a>
      </div>
      <div className='bb'>
        <NavLink to={'/'}><Logo from='bb' variant={defaultBbpv==='pv'?'blanc':'blanc'} link={false}/>
        <div className='desc'>{Utils.parseWithLinks(t('footer-bb','html'))}</div>
        </NavLink>
      </div>
    </>:<>
      <div className='pv'>
        <NavLink to={'/'}><Logo from='pv' variant={defaultBbpv==='bb'?'blanc':'blanc'} link={false}/>
        <div className='desc'>{Utils.parseWithLinks(t('footer-pv','html'))}</div>
        </NavLink>
      </div>
      <div className='bb active'>
        <a href={`${Conf.urlsBb[0]}?lang=${lang}`}><Logo from='bb' variant={defaultBbpv==='pv'?'blanc':'blanc'} link={false}/>
        <div className='desc'>{Utils.parseWithLinks(t('footer-bb','html'))}</div>
        <Suivant className='svg-color-bb'/></a>
      </div>
    </>}
    {screenSize!=='xs' && <div className='separateur'></div>}
    <div className='reseaux'>
      <Newsletter/>
      <div className='rs'>
        <a href={t('url-fb')} target="_blank" rel="noreferrer"><Fb/></a>
        <a href={t('url-insta')} target="_blank" rel="noreferrer"><Insta/></a>
      </div>
    </div>
  </div>;
}
export default Footer;
