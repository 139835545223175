import React, { useState, createContext, useEffect, useCallback } from "react";
import { useQuery } from '@apollo/client';
import Queries from 'utils/Queries';
import {
  useLocation,
} from "react-router-dom";
import Conf from 'utils/Conf';
import Utils from 'utils/Utils';
export const SettingsContext = createContext({});

let userLang=navigator.language.startsWith('fr') ? 'fr-FR' : 'en-US';
const searchParams = new URLSearchParams(window.location.search);
if (searchParams.has('lang')) userLang=searchParams.get('lang').startsWith('fr') ? 'fr-FR' : (searchParams.get('lang').startsWith('en')? 'en-US' : userLang);

const SettingsProvider = ({clientSystem,width,children})=>{
    const { pathname } = useLocation();
    const [ screenSize, setScreenSize ] = useState("xxl");
    const [ appState, setAppState ] = useState({
      menuOpen:false,
      newsOpen:false,
      filter:'',
      page:1,
      lang:userLang,
      defaultLang:'fr-FR',
      defaultBbpv:Conf.urlsPv.reduce((acc,o)=>acc||window.location.href.startsWith(o),false) ? 'pv' : 'bb',
      bbpv:'bb',
      formState:{
        nom:'',
        prenom:'',
        email:'',
        message:'',
      },
      sortOrder:1,
      sortField:'titre',
    });
    const { data,refetch } = useQuery(Queries.settings);
    useEffect(()=>{
      refetch();
    },[refetch]);
    const trads=data && data.interface ? data.interface.reduce((acc,item,i)=>{
        const cle=item.cle;
        const translations=item.translations.reduce((accTr,tr,j)=>{
            const lang=tr.languages_code.code;
            const valeurTr=tr.valeur;
            const htmlTr=tr.html;
            return {[lang]:{valeur:valeurTr,html:htmlTr},...accTr};
        },{});
        return {[cle]:{translations},...acc};
    },{}) : {};
    const t=(key,type='valeur')=>{
      const tr=trads[key] ? ( trads[key].translations[appState.lang] || trads[key].translations[appState.defaultLang] ) : {value:null, html:null};
      return  (tr && tr[type]) || '';
    };
    const translate=useCallback((item,key)=>{
        return Utils.translate(item,appState.lang,appState.defaultLang,key);
    },[appState.lang,appState.defaultLang]);
    useEffect(()=>{
        const resize=()=>{
          const width=window.innerWidth;
          let size="xs";
          if (width>=576) size="sm";
          if (width>=768) size="md";
          if (width>=992) size="lg";
          if (width>=1200) size="xl";
          if (width>=1600) size="xxl";
          setScreenSize(size);
        }
        resize();
        window.addEventListener('resize',resize);
        return ()=>{
          window.removeEventListener('resize',resize);
        }
    },[setScreenSize]);
    useEffect(()=>{
      let {bbpv} = (new URL(document.location)).searchParams;
      if (bbpv) {
        setAppState(state=>{
          return {...state, bbpv}
        })
      }
    },[setAppState]);
    useEffect(()=>{
      document.documentElement.style.setProperty('--main-color', appState.bbpv==='bb' ? '#4e5dff' : '#fd4700' );
    },[appState.bbpv]);
    useEffect(()=>{
      document.documentElement.style.setProperty('--main-color-default', appState.defaultBbpv==='bb' ? '#4e5dff' : '#fd4700' );
      document.documentElement.style.setProperty('--main-color-default-transparent', appState.defaultBbpv==='bb' ? '#4e5dff00' : '#fd470000' );
    },[appState.defaultBbpv]);
    useEffect(()=>{
      document.documentElement.style.setProperty('--side-bar-width', screenSize==='xs' || screenSize==='sm' ? '40px' : '60px' );
      document.documentElement.style.setProperty('--side-bar-width-half', screenSize==='xs' || screenSize==='sm' ? '20px' : '30px' );
      document.documentElement.style.setProperty('--side-bar-width-negative', screenSize==='xs' || screenSize==='sm' ? '-40px' : '-60px' );
    },[screenSize]);
    const removeScrollY=(pathname)=>{
      setAppState((state)=>{return{...state,pagesScrollY:{...state.pagesScrollY,[pathname]:null}}});
    };
    const langs= data ? data.languages.map(o=>{return{...o,label:o.code.split('-')[0]}}) : [];
    const value={
      removeScrollY,
      data,
      clientSystem,
      screenSize,
      trads,
      lang:appState.lang,
      langs,
      t,
      translate,
      width,
      pathname,
      appState,
      setAppState,
      nb:data && data.projets ? data.projets.length : 0,
    };
    return (
        <SettingsContext.Provider value={value}>
            {data && <div className="top-container">{children}</div>}
        </SettingsContext.Provider>
    );
}
export default SettingsProvider;
