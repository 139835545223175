import React, {useContext} from "react";
import './ProjetLine.scss'
import Conf from 'utils/Conf';
import { SettingsContext } from 'Providers/SettingsProvider';
import { Link } from "react-router-dom";

function ProjetLine({projet,catalogue=false}) {
  const { translate } = useContext(SettingsContext);
  const img=projet.visuel;
  return <Link className='projet-line-link' to={(catalogue?'/catalogue/':'/projet/')+(projet.slugs && projet.slugs[0] ? projet.slugs[0].slug : projet.id)}><div className='projet-line'>
  <div className="thumbnail">
    {img && <img src={Conf.apiUrl+`/assets/${img.id}?key=mini`} alt={img.id}/>}
  </div>
  <div className="titre">
    {translate(projet,'titre')}
  </div>
  <div className="auteur">
    {projet.auteur}
  </div>
  <div className="date">
    {projet.date}
  </div>
  <div className="type">
    {projet.type && translate(projet.type,'nom')}
  </div>
  </div>
  </Link>
}
export default ProjetLine;
