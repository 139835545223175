import React, {useEffect} from "react";
import InnerMentions from './InnerMentions';
import Queries from 'utils/Queries';
import { useQuery } from '@apollo/client';
import './Mentions.scss'

function Mentions() {
  const { data,refetch } = useQuery(Queries.mentions);
  useEffect(()=>{
    refetch();
  },[refetch]);
  const mentions=data && data.mentions ? data.mentions : {};
  return <InnerMentions mentions={mentions}/>;
}
export default Mentions;
