import React, { useRef } from "react";
import './Diaporama.scss'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation } from "swiper";
import 'swiper/css';
import "swiper/css/navigation";
import Conf from 'utils/Conf';

function Diaporama({images,catalogue=false}) {
  const swiper=useRef(null);
  const handleLoad=(e)=>{
    if (swiper.current) {
      swiper.current.update();
      swiper.current.enable();
      swiper.current.activeIndex=0;
    }
  }
  return <div className={'diaporama'+(catalogue?' catalogue-diaporama':'')}>
    <Swiper
      slidesPerView={"auto"}
      spaceBetween={10}
      navigation={true}
      modules={[Navigation]}
      onSwiper={(s) => swiper.current=s}
      enabled={false}
      centeredSlides={true}
    >
      {images.map((img,i)=><SwiperSlide key={img.id} >
        {({ isActive }) => <img alt={img.id} onLoad={handleLoad} src={Conf.apiUrl+`/assets/${img.id}?key=diaporama`} className={isActive ? ' active' : ''}/>}
      </SwiperSlide>)}
    </Swiper>
  </div>;
}
export default Diaporama;
