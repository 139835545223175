import React, { useContext, useEffect } from "react";
import LangSwitch from 'components/LangSwitch';
import { NavLink } from "react-router-dom";
import { SettingsContext } from 'Providers/SettingsProvider';
import Drawer from './Drawer';
import NewsletterDrawer from './NewsletterDrawer';
import { useLocation } from 'react-router-dom';

import './MainMenu.scss'

function MainMenu({id,mobile=false}) {
  const { t,setAppState } = useContext(SettingsContext);
  const location = useLocation();
  const toggle=()=>{
    setAppState(state=>{return{...state,menuOpen:state.newsOpen ? false : !state.menuOpen,newsOpen:false}});
  }
  useEffect(() => {
    setAppState(state=>{return{...state,menuOpen:false,newsOpen:false}});
  }, [location,setAppState]);
  return <div className='main-menu'>
    <div className='sidebar'>
      <div className='up'>
        <button className="menu-toggle menu-item" onClick={toggle}>
          <svg width="22" height="16" viewBox="0 0 22 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 7.66659H21" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M1 14.3333H21" strokeWidth="1.5" strokeLinecap="round"/>
            <path d="M1 0.999837H21" strokeWidth="1.5" strokeLinecap="round"/>
          </svg>
        </button>
        <NavLink to={'/'} className='menu-item'>
          <svg viewBox="0 0 22 22" fill="none" className="home">
            <rect x="3.4" y="8.1" width="15.3" height="11.9" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <polygon points="11,1.5 2.3,8.1 19.8,8.1" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </NavLink>
        <NavLink to={'/catalogue'} className='menu-item'>
          <svg viewBox="0 0 22 22" fill="none" className="loupe">
            <path fillRule="evenodd" clipRule="evenodd" d="M1 9.23645C1.00004 5.30731 3.77566 1.92538 7.62933 1.159C11.483 0.392614 15.3414 2.45523 16.8448 6.08539C18.3481 9.71555 17.0777 13.9022 13.8106 16.0847C10.5434 18.2673 6.18939 17.838 3.41143 15.0593C1.86738 13.5149 0.999978 11.4204 1 9.23645Z" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
            <path d="M17.4702 17.4707L21.0002 21.0007" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
          </svg>
        </NavLink>
      </div>
      <div className='down'>
        <NavLink to={'/contact'} className='menu-contact'>
          <div>{t('contactez-nous')}</div>
        </NavLink>
        <LangSwitch/>
      </div>
    </div>
    <Drawer/>
    <NewsletterDrawer/>
  </div>
  ;
}
export default MainMenu;
