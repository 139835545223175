import parse, { domToReact } from 'html-react-parser';
import { Link } from 'react-router-dom';
import { Email } from "react-obfuscate-email";
const filterListe=(data,filter,translate,sortField,sortOrder)=>{
  if (data && data.projets) {
    let normalizedSearch=accentsTidyLw(filter);
    return data.projets.filter((o)=>{
      if (filter.length<2) return true;
      if (
        accentsTidyLw(translate(o,'titre') || '').indexOf(normalizedSearch)!==-1
        || accentsTidyLw(o.auteur || '').indexOf(normalizedSearch)!==-1
        || accentsTidyLw(o.date || '').indexOf(normalizedSearch)!==-1
        || ( o.type && accentsTidyLw(translate(o.type,'nom') || '').indexOf(normalizedSearch)!==-1 )
      ) return true;
      return false;
    }).sort((a,b)=>{
      if (sortField!=='type') {
        const sa=accentsTidyLw(translate(a,sortField).trim());
        const sb=accentsTidyLw(translate(b,sortField).trim());
        const res= sa.localeCompare(sb);
        return sortOrder===1 ? res : -res;
      } else {
        const sa=accentsTidyLw(a.type && translate(a.type,'nom'));
        const sb=accentsTidyLw(b.type && translate(b.type,'nom'));
        const res= sa.localeCompare(sb);
        return sortOrder===1 ? res : -res;
      }
    });
  } else {
    return [];
  }
}
const nbsp=(text)=>{
  let res=text+'';
  res=res.replace(/[ ]+!/gm,"&nbsp;!");
  res=res.replace(/[ ]+\?/gm,"&nbsp;?");
  res=res.replace(/[ ]+:/gm,"&nbsp;:");
  res=res.replace(/[ ]+;/gm,"&nbsp;;");
  return res;
}
const truncateString = (str, num)=>{
  let tab=str ? str.split(' ') : [];
  if (tab.length <= num) {
    return str
  }
  return tab.slice(0, num).join(' ') + '...'
};
const computeImgSize= ({img,w,h}) => {
  const {width,height}=img;
  const res={width,height};
  if(w) {
    res.width=w;
    res.height=height*w/width;
  }
  if(h) {
    res.height=h;
    res.width=width*h/height;
  }
  return res;
}
const parseInlineStyle=(style)=>{
  const template = document.createElement('template');
  template.setAttribute('style', style)
  return Object.entries(template.style)
    .filter((key) => !/^[0-9]+$/.test(key))
    .filter(([ , value ]) => Boolean(value))
    .reduce((acc, [ idx, key ]) => ({ ...acc, [key]: template.style[key] }), {});
}

const parseWithLinks = (text,replacements=[])=>{
  if (text) {
    let res=nbsp(text);
    const options = {
      replace: ({ type, data, name, attribs, children }) => {
        if (
          name === 'a'
          && attribs.href
          && attribs.href.indexOf('mailto:')!==-1
        ) {
          const mail=attribs.href.replace('mailto:','');
          return <Email email={mail}></Email>;
        }
        if (
          name === 'iframe'
        ) {
          const {width,height,style,...others} = attribs;
          const findFor = ['accept','acceptcharset','accesskey','action','allowfullscreen','alt','async','autocomplete','autofocus','autoplay','capture','cellpadding','cellspacing','challenge','charset','checked','cite','classid','classname','colspan','cols','content','contenteditable','contextmenu','controls','controlslist','coords','crossorigin','data','datetime','default','defer','dir','disabled','download','draggable','enctype','form','formaction','formenctype','formmethod','formnovalidate','formtarget','frameborder','headers','height','hidden','high','href','hreflang','htmlfor','httpequiv','icon','id','inputmode','integrity','is','keyparams','keytype','kind','label','lang','list','loop','low','manifest','marginheight','marginwidth','max','maxlength','media','mediagroup','method','min','minlength','multiple','muted','name','novalidate','nonce','open','optimum','pattern','placeholder','poster','preload','profile','radiogroup','readonly','rel','required','reversed','role','rowspan','rows','sandbox','scope','scoped','scrolling','seamless','selected','shape','size','sizes','span','spellcheck','src','srcdoc','srclang','srcset','start','step','style','summary','tabindex','target','title','type','usemap','value','width','wmode','wrap'];
          const replaceWith = ['accept','acceptCharset','accessKey','action','allowFullScreen','alt','async','autoComplete','autoFocus','autoPlay','capture','cellPadding','cellSpacing','challenge','charSet','checked','cite','classID','className','colSpan','cols','content','contentEditable','contextMenu','controls','controlsList','coords','crossOrigin','data','dateTime','default','defer','dir','disabled','download','draggable','encType','form','formAction','formEncType','formMethod','formNoValidate','formTarget','frameBorder','headers','height','hidden','high','href','hrefLang','htmlFor','httpEquiv','icon','id','inputMode','integrity','is','keyParams','keyType','kind','label','lang','list','loop','low','manifest','marginHeight','marginWidth','max','maxLength','media','mediaGroup','method','min','minLength','multiple','muted','name','noValidate','nonce','open','optimum','pattern','placeholder','poster','preload','profile','radioGroup','readOnly','rel','required','reversed','role','rowSpan','rows','sandbox','scope','scoped','scrolling','seamless','selected','shape','size','sizes','span','spellCheck','src','srcDoc','srcLang','srcSet','start','step','style','summary','tabIndex','target','title','type','useMap','value','width','wmode','wrap'];
          const parsedAttrs={};
          Object.keys(others).forEach(attr=>{
            let value=others[attr]
            let modifiedAttr=attr;
            findFor.forEach( (tag, i) => modifiedAttr = modifiedAttr.replace(new RegExp(tag, "g"), replaceWith[i]) );
            parsedAttrs[modifiedAttr]=value;
          })
          const timestamp=Date.now();
          const parsedStyle=parseInlineStyle(style);
          return <iframe title={'embed'+timestamp} {...parsedAttrs} style={parsedStyle}/>;
        }
        if (
          name === 'a'
          && attribs.href
          && attribs.href.indexOf('://')===-1
        ) {
          return <Link to={attribs.href}>{domToReact(children)}</Link>;
        }
        for (const item of replacements) {
          if (attribs && attribs.id === item.id) return item.replacement;
        }
      }
    };
    return parse(res, options);
  }
  return '';
}
const cumulativeOffset = (element)=>{
    let top = 0, left = 0;
    do {
        top += element.offsetTop  || 0;
        left += element.offsetLeft || 0;
        element = element.offsetParent;
    } while(element);
    return {
        top: top,
        left: left
    };
}
const jump = (h)=>{
    const node = document.getElementById(h);
    let top=0;
    if (node) {
        top=cumulativeOffset(node).top;
    }
    window.scrollTo(0, top-15);
}
const path2type = (path)=>{
    const tab=path.split('/');
    const last=tab[tab.length-1];
    const tabLast=last.split('-');
    return tabLast[0];
}
const translate = (item,lang,defaultLang,key) => {
    let res=item ? item[key] : null;
    if (item.translations) {
      const tr=item.translations.find((o)=>o.languages_id ? o.languages_id.code===lang : o.languages_code && o.languages_code.code===lang);
      const defaultTr=item.translations.find((o)=>o.languages_id ? o.languages_id.code===defaultLang : o.languages_code && o.languages_code.code===defaultLang);
      if (defaultTr && defaultTr[key]) res=defaultTr[key];
      if (tr && tr[key]) res=tr[key];
    }
    return res;
}
const accentsTidy = (s)=>{
    var map = [
        ["[àáâãäåāăąǎǟǡǻȁȃȧ]", "a"],
        ["[æ]", "ae"],
        ["[ƀƃɓ]", "b"],
        ["[çćĉċčƈȼɕ]", "c"],
        ["[ďđƌȡɖɗ]", "d"],
        ["[èéêëēĕėęěȅȇȩɇ]", "e"],
        ["[ƒ]", "f"],
        ["[ĝğġģǥǧǵɠ]", "g"],
        ["[ĥħȟɦ]", "h"],
        ["[ìíîïĩīĭįǐȉȋɨ]", "i"],
        ["[ĵǰɉʝ]", "j"],
        ["[ķƙǩ]", "k"],
        ["[ĺļľŀłƚȴɫɬɭ]", "l"],
        ["[ɱ]", "m"],
        ["[ñńņňƞǹȵɲɳ]", "n"],
        ["[òóôõöøōŏőơǒǫǭǿȍȏȫȭȯȱ]", "o"],
        ["[œ]", "oe"],
        ["[ƥ]", "p"],
        ["[ɋʠ]", "q"],
        ["[ŕŗřȑȓɍɼɽɾ]", "r"],
        ["[śŝşšșȿʂ]", "s"],
        ["[ţťŧƫƭțȶʈ]", "t"],
        ["[ùúûüũūŭůűųưǔǖǘǚǜȕȗ]", "u"],
        ["[ʋ]", "v"],
        ["[ŵ]", "w"],
        ["[ýÿŷƴȳɏ]", "y"],
        ["[źżžƶǅȥɀʐʑ]", "z"],
        ["[’]", "'"],
        ["[-_,.;:!?]", " "],
    ];
    for (var i=0; i<map.length; ++i) {
        s = s ? s.replace(new RegExp(map[i][0], "gi"), ((j)=> {
            return (match) => {
                if (match.toUpperCase() === match) {
                    return map[j][1].toUpperCase();
                } else {
                    return map[j][1];
                }
            }
        })(i)) : '';
    }
    return s;
};
const accentsTidyLw = (s)=>{
    return accentsTidy(s).toLowerCase();
};
const validateEmail = (email) => {
 return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
}
const utils={
    jump,
    cumulativeOffset,
    path2type,
    parseWithLinks,
    nbsp,
    translate,
    truncateString,
    computeImgSize,
    accentsTidy,
    accentsTidyLw,
    validateEmail,
    filterListe,
}
export default utils;
