import React, { useContext } from "react";
import './Pagination.scss';
import { SettingsContext } from 'Providers/SettingsProvider';
import {ReactComponent as FlecheIcon} from 'assets/fleche_fiche.svg';
import {ReactComponent as FlecheDoubleIcon} from 'assets/fleche_double.svg';

const two=(n)=>n<10 ? '0'+n : n;

function Pagination({nb,perPage,onPageChange}) {
  const { appState,setAppState } = useContext(SettingsContext);
  const nbPages=parseInt(Math.ceil(nb/perPage));
  const handleChange=(page)=>{
    setAppState((state)=>{return {...state,page}});
  }
  const {page} = appState;
  const min=Math.min(Math.max(page-1,1),nbPages-2);
  return <>
  <div className="pagination">
      {nbPages>1 && <div className="pagination-inner">
          <span className="chevron left" onClick={()=>handleChange(1)}>{<FlecheDoubleIcon/>}</span>
          <span className="chevron left" onClick={()=>handleChange(Math.max(1,page-1))}>{<FlecheIcon/>}</span>
          {[min,min+1,min+2].filter(o=>o>0 && o<=nbPages).map((o)=><span key={o} className={"page-dot"+(o===page ? ' active':'')} onClick={()=>handleChange(o)}>{two(o)}</span>)}
          <span className="chevron" onClick={()=>handleChange(Math.min(nbPages,page+1))}>{<FlecheIcon/>}</span>
          <span className="chevron" onClick={()=>handleChange(nbPages)}>{<FlecheDoubleIcon/>}</span>
      </div>}
  </div>
  </>;
}

export default Pagination;
