import React, { useState, useContext, useMemo, useCallback, useRef, useLayoutEffect } from "react";
import axios from 'axios';
import { SettingsContext } from 'Providers/SettingsProvider';
import {ReactComponent as Check} from 'assets/check.svg';
import Utils from 'utils/Utils';
import Conf from 'utils/Conf';
import './ContactForm.scss'

function ContactForm() {
  const { t, setAppState, appState } = useContext(SettingsContext);
  const {formState} = appState;
  const setFormState=useCallback((k,v)=>{
    setAppState(state=>{return {...state,formState:{...state.formState,[k]:v}}});
  },[setAppState]);
  const [ pending, setPending ] = useState(false);
  const [ success, setSuccess ] = useState(false);
  const [ height, setHeight ] = useState('3em');
  const textarea=useRef(null);
  useLayoutEffect(()=>{
    textarea.current.style.height = 0;
    textarea.current.style.height = (textarea.current.scrollHeight) + "px";
  },[setHeight,formState.message]);
  const valid=useMemo(()=>
    Utils.validateEmail(formState.email)
    && formState.message
  ,[formState]);
  const send=useCallback((e)=>{
    e.preventDefault();
    if (valid) {
      let timer;
      setPending(true);
      axios.post(Conf.docUrl+'/message',{...formState},{responseType:'json'}).then((res)=>{
        setPending(false);
        if (res.data && res.data.ok) {
          setSuccess(true);
          setAppState(state=>{return{...state,formState:{
            nom:'',
            prenom:'',
            email:'',
            message:'',
          }}});
          timer=setTimeout(()=>{
            setSuccess(false);
          },3000);
        }
      }).catch((err)=>{
        setPending(false);
      });
      return ()=>{
        clearTimeout(timer);
      }
    }
  },[formState,valid,setAppState]);
  return <div className='contact-form'>
    <form onSubmit={send}>
      <div className='contact-cols'>
        <input name="nom" placeholder={t('contact-form-nom')} value={formState.nom} onChange={(e)=>setFormState('nom',e.target.value)} disabled={pending}/>
        <input name="prenom" placeholder={t('contact-form-prenom')} value={formState.prenom} onChange={(e)=>setFormState('prenom',e.target.value)} disabled={pending}/>
      </div>
      <input name="email" placeholder={t('contact-form-email')} value={formState.email} onChange={(e)=>setFormState('email',e.target.value)} disabled={pending}/>
      <textarea ref={textarea} name="message" style={{height}} placeholder={t('contact-form-message')} value={formState.message} onChange={(e)=>setFormState('message',e.target.value)} disabled={pending}>
      </textarea>
      <div className="spacer-small"></div>
      <button type="submit" disabled={pending} className={valid ? 'valid' : ''}>
        {success ? <>{t('contact-form-ok')} <Check className='valid'/></> : <span className={(valid ? 'valid' : '')+(pending ? ' pending' : '')}>{t('contact-form-envoyer')}</span>}
      </button>
    </form>
  </div>;
}
export default ContactForm;
