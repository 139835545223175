import React, { useEffect, useContext, useState, useLayoutEffect} from "react";
import { useQuery } from '@apollo/client';
import Logo from 'components/Logo';
import ProjetTile from 'components/ProjetTile';
import Cercle from 'components/Cercle';
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';
import './Une.scss'
import {ReactComponent as BtList} from 'assets/bt_list.svg';
import { NavLink } from "react-router-dom";

function Une() {
  const { appState, screenSize, t } = useContext(SettingsContext);
  const [ loaded, setLoaded ] = useState(false);
  const [ ready,setReady ] = useState(false);
  const [ scroll,setScroll ] = useState(0);
  const { data,refetch } = useQuery(Queries.une,{
    variables:{
      catalogue:appState.defaultBbpv,
    }
  });
  useEffect(()=>{
    refetch();
  },[refetch]);
  useLayoutEffect(()=>{
    const timer=setTimeout(()=>setLoaded(true),100);
    return ()=>clearTimeout(timer);
  },[setLoaded]);
  useEffect(()=>{
    const handleScroll=()=>{
      setScroll(window.scrollY);
    }
    window.addEventListener('scroll',handleScroll);
    return ()=>{
      window.removeEventListener('scroll',handleScroll);
    }
  },[setScroll]);
  useEffect(()=>{
    if (loaded && data && data.projets) setReady(true);
  },[data,loaded,setReady]);
  const { projets } = data || {};
  const { defaultBbpv } = appState;
  console.log(ready);
  return <div className={'une'+(loaded ? ' loaded':'')+(ready ? ' ready':'')}>
  <div className='main-header'>
    <div className="cercle-wrapper" style={{transform:`translate3d(0,${scroll*0.5}px,0)`}}>
    <Cercle color={true}/>
    </div>
    <Logo from={defaultBbpv}/>
  </div>
  {screenSize!=='xs' && <div className="spacer"></div>}
  {screenSize!=='xs' && <div className="spacer"></div>}
  <div className="spacer"></div>
  <div className="spacer"></div>
  <div className="colonnes">
    {['xl','xxl'].indexOf(screenSize)!==-1 && [1,2,3].map(idx=><div key={'col-'+idx} className={'colonne-3'} style={{marginTop:`${idx!==2 ? -scroll*0.1 : 0}px`}}>
      {projets && projets.filter((o,i)=>i%3===idx-1).map(projet=><ProjetTile key={projet.id} projet={projet}/>)}
    </div>)}
    {['lg','md'].indexOf(screenSize)!==-1 && [1,2].map(idx=><div key={'col-'+idx} className={'colonne-2'} style={{marginTop:`${idx!==2 ? -scroll*0.1 : 0}px`}}>
      {projets && projets.filter((o,i)=>i%2===idx-1).map(projet=><ProjetTile key={projet.id} projet={projet}/>)}
    </div>)}
    {['xs','sm'].indexOf(screenSize)!==-1 && <div className={'colonne-1'}>
      {projets && projets.map(projet=><ProjetTile key={projet.id} projet={projet}/>)}
    </div>}
  </div>
  <div className="spacer"></div>
  <div className='une-action'>
    <NavLink to={'/catalogue'} className='btn-catalog'>{t('tout-le-catalogue')} <span><BtList/></span></NavLink>
  </div>
  <div className="spacer"></div>
  <div className="spacer-small"></div>
  </div>;
}
export default Une;
