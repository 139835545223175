import React, { useState, useContext, useMemo, useCallback } from "react";
import './Newsletter.scss'
import { SettingsContext } from 'Providers/SettingsProvider';
import {ReactComponent as Suivant} from 'assets/suivant.svg';
import {ReactComponent as Enveloppe} from 'assets/env.svg';
import {ReactComponent as Check} from 'assets/check.svg';
import Utils from 'utils/Utils';
import Conf from 'utils/Conf';
import axios from 'axios';

function Newsletter({color}) {
  const [ email, setEmail ] = useState('');
  const [ pending, setPending ] = useState(false);
  const [ success, setSuccess ] = useState(false);
  const { t, setAppState } = useContext(SettingsContext);
  const valid=useMemo(()=>Utils.validateEmail(email),[email]);
  const send=useCallback((e)=>{
    e.preventDefault();
    if (valid) {
      let timer;
      setPending(true);
      axios.post(Conf.docUrl+'/subscribe',{email},{responseType:'json'}).then((res)=>{
        setPending(false);
        if (res.data && res.data.ok) {
          setSuccess(true);
          setEmail('');
          timer=setTimeout(()=>{
            setSuccess(false);
            setAppState(state=>{return { ...state, newsOpen:false}});
          },3000);
        }
      });
      return ()=>{
        clearTimeout(timer);
      }
    }
  },[email,valid,setAppState]);
  return <div className='newsletter'>
    <h3>{t('newsletter')}</h3>
    <div className='texte'>{Utils.parseWithLinks(t('newsletter','html'))}</div>
    <form onSubmit={send} action="">
      <Enveloppe/>
      <input name="email" placeholder={t('votre-email')} value={email} onChange={(e)=>setEmail(e.target.value)} disabled={pending}/>
      <button type="submit" disabled={pending}>
        {success ? <Check className='valid'/> : <Suivant className={(valid ? 'valid' : '')+(pending ? ' pending' : '')}/>}
      </button>
    </form>
  </div>;
}
export default Newsletter;
