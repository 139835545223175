import React, { useEffect, useContext, useRef, useState, useMemo, useLayoutEffect } from "react";
import { useQuery } from '@apollo/client';
import Utils from 'utils/Utils';
import Cercle from 'components/Cercle';
import ProjetLine from 'components/ProjetLine';
import Pagination from 'components/Pagination';
import TextField from '@mui/material/TextField';
import Queries from 'utils/Queries';
import { SettingsContext } from 'Providers/SettingsProvider';
import {ReactComponent as SearchIcon} from 'assets/loupe.svg';
import {ReactComponent as Drop} from 'assets/fleche_drop.svg';
import './Catalogue.scss'

const perPage=15;

function Catalogue() {
  const { t, translate, appState, setAppState, screenSize } = useContext(SettingsContext);
  const [ loaded, setLoaded ] = useState(false);
  const inputRef=useRef(null);
  const [ open,setOpen ] = useState(false);
  const [ ready,setReady ] = useState(false);
  const { data,refetch } = useQuery(Queries.catalogue);
  const { filter, page, sortField, sortOrder } = appState;
  const handleFilterChange= (e)=>{
    setAppState(state=>{return {...state, filter:e.target.value,page:1}});
  }
  const handlePageChange= (page)=>{
    setAppState(state=>{return {...state, page}});
  }
  const handleSortFieldChange= (field)=>{
    setAppState(state=>{
      const f=field;
      const order=state.sortField===field ? -1*state.sortOrder : 1;
      return {...state, sortField:f, sortOrder:order};
    });
  }
  useEffect(()=>{
    refetch();
  },[refetch]);
  useEffect(()=>{
    if (data && data.projets) setReady(true);
  },[data,setReady]);
  useEffect(()=>{
    console.log('page');
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  },[page]);
  useEffect(()=>{
    if(inputRef.current) {
      const node=inputRef.current;
      const handleFocus=()=>{
        setOpen(true);
      }
      const handleBlur=()=>{
        setOpen(false);
      }
      node.addEventListener('focus',handleFocus);
      node.addEventListener('blur',handleBlur);
      return ()=>{
        node.removeEventListener('focus',handleFocus);
        node.removeEventListener('blur',handleBlur);
      }
    }
  },[setOpen]);
  useLayoutEffect(()=>{
    const timer=setTimeout(()=>setLoaded(true),100);
    return ()=>clearTimeout(timer);
  },[setLoaded]);
  const filtered=useMemo(()=>Utils.filterListe(data,filter,translate,sortField,sortOrder),[data,filter,translate,sortField,sortOrder]);
  return <div className={'catalogue'+(loaded ? ' loaded':'')+(ready ? ' ready':'')}>
  <div className='main-header'>
    <Cercle/>
  </div>
  <div className='header'>
    <div className='main-title'>{t('catalogue')}</div>
    <div className='main-subtitle'>{Utils.parseWithLinks(t('catalogue-desc','html'))}</div>
  </div>
  <div className={'recherche'+(open ? ' open':'')}>
    <div className='input'>
      <SearchIcon />
      <TextField label={t('recherche')} variant='filled' size='small' inputRef={inputRef} value={filter} onChange={handleFilterChange}/>
    </div>
  </div>
  <div className="spacer spacer-catalogue"></div>
  <div className="lines">
    <div className="catalog-header">
      {['xs','sm'].indexOf(screenSize)===-1 && <div className="visuel"></div>}
      <div className="titre" onClick={()=>handleSortFieldChange('titre')}>{t('catalog-header-titre')} <Drop className={(sortField==='titre'?'active':'')+(sortField==='titre'?(sortOrder===1?' down':' up'):' down')}/></div>
      <div className="auteur" onClick={()=>handleSortFieldChange('auteur')}>{t('catalog-header-de')} <Drop className={(sortField==='auteur'?'active':'')+(sortField==='auteur'?(sortOrder===1?' down':' up'):' down')}/></div>
      <div className="date" onClick={()=>handleSortFieldChange('date')}>{t('catalog-header-date')} <Drop className={(sortField==='date'?'active':'')+(sortField==='date'?(sortOrder===1?' down':' up'):' down')}/></div>
      <div className="type" onClick={()=>handleSortFieldChange('type')}>{t('catalog-header-type')} <Drop className={(sortField==='type'?'active':'')+(sortField==='type'?(sortOrder===1?' down':' up'):' down')}/></div>
    </div>
    {filtered.filter((o,i)=>i>=(page-1)*perPage && i<page*perPage).map((projet)=><ProjetLine key={projet.id} projet={projet} catalogue/>)}
  </div>
  <div className="spacer-small"></div>
  <Pagination nb={filtered.length} perPage={perPage} onPageChange={handlePageChange}/>
  <div className="spacer"></div>
  </div>;
}
export default Catalogue;
