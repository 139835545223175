import React, {useContext, useRef, useEffect} from "react";
import './ProjetTile.scss'
import Conf from 'utils/Conf';
import { SettingsContext } from 'Providers/SettingsProvider';
import { Link } from "react-router-dom";

function ProjetTile({projet}) {
  const { translate } = useContext(SettingsContext);
  const neige=useRef(null);
  const img=projet.visuel_une;
  useEffect(()=>{
    const element = neige.current;
    let tempsPrecedent=0;
    const iteration=(chrono)=>{
      if (tempsPrecedent===0 || chrono-tempsPrecedent>50) {
        tempsPrecedent = chrono;
        element.style.transform = `translate3d(-${Math.random()*75}%,-${Math.random()*75}%,0)`;
      }
      if (element) window.requestAnimationFrame(iteration);

    }
    window.requestAnimationFrame(iteration);
  },[]);
  return <Link className='projet-line-link' to={'/projet/'+(projet.slugs && projet.slugs[0] ? projet.slugs[0].slug : projet.id)}>
    <div className='projet-tile'>
      <div className="thumbnail">
        {img && <img src={Conf.apiUrl+`/assets/${img.id}?key=une`} alt={img.id}/>}
        <div className="mask">
          <div className="neige" ref={neige}></div>
        </div>
        <div className="btn">
          <div>
            <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" viewBox="-2 -2 63 63">
              <g id="Layer_1-2" data-name="Layer 1">
                <g>
                  <path d="M29.5,24.98c-2.49,0-4.52,2.03-4.52,4.52s2.03,4.52,4.52,4.52,4.52-2.03,4.52-4.52-2.03-4.52-4.52-4.52Z"/>
                  <path d="M29.5,0C13.21,0,0,13.21,0,29.5s13.21,29.5,29.5,29.5,29.5-13.21,29.5-29.5S45.79,0,29.5,0Zm13.68,29.7c-1.25,2.5-3.17,4.61-5.54,6.1-2.44,1.54-5.25,2.35-8.15,2.35s-5.71-.81-8.15-2.35c-2.37-1.49-4.29-3.6-5.54-6.1l-.1-.2,.1-.2c1.25-2.5,3.17-4.61,5.54-6.1,2.44-1.54,5.25-2.35,8.15-2.35s5.71,.81,8.15,2.35c2.37,1.49,4.29,3.6,5.54,6.1l.1,.2-.1,.2Z"/>
                </g>
              </g>
            </svg>
          </div>
        </div>
      </div>
      <div className="titre">
        {translate(projet,'titre')}
      </div>
      <div className="soustitre">
        {translate(projet,'soustitre')}
      </div>
    </div>
  </Link>
}
export default ProjetTile;
