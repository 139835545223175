import React, {useContext, useState, useLayoutEffect} from "react";
import Cercle from 'components/Cercle';
import Logo from 'components/Logo';
import TitreAnime from 'components/TitreAnime';
import ContactForm from './ContactForm';
import { SettingsContext } from 'Providers/SettingsProvider';
import './Contact.scss'
import Utils from 'utils/Utils';

function InnerContact({contact}) {
  const [ loaded, setLoaded ] = useState(false);
  const { translate,t,appState } = useContext(SettingsContext);
  useLayoutEffect(()=>{
    const timer=setTimeout(()=>setLoaded(true),100);
    return ()=>clearTimeout(timer);
  },[setLoaded]);
  const adresses=translate(contact,'adresses') || [];
  const { defaultBbpv } = appState;
  return <div className={'contact'+(loaded ? ' loaded':'')}>
    <div className='main-header'>
      <Cercle color={true}/>
      <Logo from={defaultBbpv}/>
    </div>
    <div className="contact-body">
        <div className="titre">
          <TitreAnime titre={translate(contact,'titre')}/>
        </div>
        <div className="texte">
          {Utils.parseWithLinks(translate(contact,'texte'))}
        </div>
        <div className="adresses">
          {adresses.map((a,i)=><div key={i} className="adresse">
            <div className="adresses-titre">{a.titre}</div>
            <div className="adresses-value">{a.texte ? Utils.parseWithLinks(a.texte.trim()) : ''}</div>
            <div className="adresses-gps">
              {a.gps && a.gps.coordinates ? <a href={`https://www.google.es/maps?q=${a.gps.coordinates[1]},${a.gps.coordinates[0]}`} target="_blank" rel="noreferrer">
                {t('voir-sur-la-carte')}
              </a>
              : ''}
            </div>
          </div>)}
        </div>
        <div className="spacer"></div>
        <ContactForm/>
    </div>
    <div className="spacer"></div>
  </div>;
}
export default InnerContact;
