import React, {useEffect, useContext} from "react";
import InnerEquipe from './InnerEquipe';
import Queries from 'utils/Queries';
import { useQuery } from '@apollo/client';
import { SettingsContext } from 'Providers/SettingsProvider';
import './Equipe.scss'

function Equipe() {
  const { appState } = useContext(SettingsContext);
  const { data,refetch } = useQuery(Queries.equipe);
  useEffect(()=>{
    refetch();
  },[refetch]);
  const bbpv=appState.defaultBbpv;
  const equipeBb=data && data.equipe ? data.equipe : {};
  const equipePv=data && data.equipe_pv ? data.equipe_pv : {};
  return <InnerEquipe equipe={bbpv === 'bb' ? equipeBb : equipePv}/>;
}
export default Equipe;
