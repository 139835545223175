import React, {useContext, useState, useLayoutEffect, useMemo} from "react";
import Cercle from 'components/Cercle';
import Logo from 'components/Logo';
import TitreAnime from 'components/TitreAnime';
import Diaporama from 'components/Diaporama';
import Utils from 'utils/Utils';
import axios from 'axios';
import { SettingsContext } from 'Providers/SettingsProvider';
import './Projet.scss'
import Conf from 'utils/Conf';
import { saveAs } from 'file-saver';
import { NavLink } from "react-router-dom";
import {ReactComponent as Suivant} from 'assets/suivant.svg';
import {ReactComponent as Download} from 'assets/fleche_telecharger_pdf.svg';

function InnerProjet({projet,liste=[],catalogue=false}) {
  const [ loaded, setLoaded ] = useState(false);
  const { translate,t,lang,appState } = useContext(SettingsContext);
  const { filter, sortField, sortOrder } = appState;
  const handlePdf=()=>{
    axios.post(Conf.docUrl+'/pdf-projet',{projet,lang,footer:t('footer-pdf','html')},{responseType:'blob'}).then((res)=>{
      var blob = new Blob([res.data], {
        type: 'application/pdf'
      });
      saveAs(blob, `${(projet.slugs[0] ? projet.slugs[0].slug : 'projet'+projet.id)}.pdf`);
    });
  }
  useLayoutEffect(()=>{
    const timer=setTimeout(()=>setLoaded(true),100);
    return ()=>clearTimeout(timer);
  },[setLoaded]);
  const meta=translate(projet,'meta') || [];
  const filtered=useMemo(()=>catalogue ? Utils.filterListe({projets:liste},filter,translate,sortField,sortOrder):liste,[liste,filter,translate,sortField,sortOrder,catalogue]);
  const idx=filtered.findIndex(o=>o.id===projet.id);
  const prevIdx=idx>0 ? idx-1 : -1;
  const nextIdx=idx<filtered.length-1 ? idx+1 : -1;
  const prev=prevIdx!==-1 ? filtered[prevIdx] : null;
  const next=nextIdx!==-1 ? filtered[nextIdx] : null;
  return <div className={'projet'+(loaded ? ' loaded':'')+(catalogue ? ' catalogue-projet':'')}>
    <Cercle color={!catalogue}/>
    {!catalogue && <Logo from={projet.catalogue}/>}
    <div className="projet-body">
      <div className="gauche">
        <div className="titre">
          <TitreAnime titre={translate(projet,'titre')}/>
        </div>
        <div className="soustitre">
          {translate(projet,'soustitre')}
        </div>
        <div className="texte">
          {Utils.parseWithLinks(translate(projet,'pitch'))}
        </div>
      </div>
      <div className="droite">
        <div className="meta">
          {meta.map(p=><div key={p.cle}>
            <div className="meta-key">{p.cle}</div>
            <div className="meta-value">{p.valeur ? Utils.parseWithLinks(p.valeur.trim()) : ''}</div>
          </div>)}
        </div>
        <button className="download-btn" onClick={handlePdf}>{t('telecharger-pdf')} <Download/></button>
      </div>
    </div>
    <div className="spacer"></div>
    <Diaporama catalogue={catalogue} images={projet.diaporama ? projet.diaporama.filter(o=>o.directus_files_id && (!o.directus_files_id.type || o.directus_files_id.type.startsWith('image/'))).sort((a,b)=>a.sort>b.sort).map(o=>{return{id:o.directus_files_id.id}}) : []}/>
    <div className="spacer"></div>
    <div className="nav">
      {prev ? <NavLink to={prev ? `${(catalogue ? '/catalogue/':'/projet/')}${prev.slugs && prev.slugs[0] ? prev.slugs[0].slug : prev.id}`:''}>
        <div className='nav-prev active'><Suivant/> {t('oeuvre-precedente')}</div>
      </NavLink>
      :
        <div className='nav-prev'><Suivant/> {t('oeuvre-precedente')}</div>
      }
      {next ? <NavLink to={next ? `${(catalogue ? '/catalogue/':'/projet/')}${next.slugs && next.slugs[0] ? next.slugs[0].slug : next.id}`:''}>
        <div className='nav-next active'>{t('oeuvre-suivante')} <Suivant/></div>
      </NavLink>
      :
        <div className='nav-next'>{t('oeuvre-suivante')} <Suivant/></div>
      }
    </div>
    <div className="spacer"></div>
  </div>;
}
export default InnerProjet;
