import React, {useEffect,useState} from "react";
import './App.scss';
import {
  BrowserRouter as Router,
  Routes,
  Route,
} from "react-router-dom";
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MainMenu from 'components/MainMenu';
import Footer from 'components/Footer';
import Une from 'components/Une';
import Catalogue from 'components/Catalogue';
import Contact from 'components/Contact';
import Equipe from 'components/Equipe';
import Mentions from 'components/Mentions';
import Projet from 'components/Projet';
import Preview from 'components/Preview';
import ScrollToTop from "components/Misc/ScrollToTop";
import SettingsProvider from "Providers/SettingsProvider";
const breakpoints={
  values: {
    xs: 0,
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1600,
  },
};
const theme = createTheme({
  breakpoints,
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#e1f0f7',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      main: '#016a66',
      // dark: will be calculated from palette.secondary.main,
      contrastText: '#ffcc00',
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indexes within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },
  components: {
    // Style sheet name ⚛️
    MuiFilledInput: {
      styleOverrides: {
        root: {
          backgroundColor:'#0000',
          color:'#FFF',
          fontFamily:'Figtree',
          height:'37px',
          '&::before,&:hover:not(.Mui-disabled)::before,&::after':{
            borderBottom:'none',
          }
        },
        input: {
          color:'#fff',
          height:'37px',
          borderSizing:'border-box',
          paddingTop:'12px',
          paddingBottom:'3px',
        }
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          color:'#FFF',
          fontFamily:'Figtree',
          fontWeight:'600',
          fontSize:'14px',
          top:'-4px',
        },
      },
    }
  },
});

function App({subRoutes,clientSystem}) {
  const [ width,setWidth ]= useState('');
  useEffect(()=>{
    const setSize=(size)=>{
      let sizes=['xs','sm','md','lg','xl','xxl'].filter((o)=>o!==size);
      document.body.classList.remove(...sizes);
      document.body.classList.add(size)
      setWidth(size);
    }
    const resize=()=>{
      if(window.innerWidth>breakpoints.values.xxl) return setSize('xxl');
      if(window.innerWidth>breakpoints.values.xl) return setSize('xl');
      if(window.innerWidth>breakpoints.values.lg) return setSize('lg');
      if(window.innerWidth>breakpoints.values.md) return setSize('md');
      if(window.innerWidth>breakpoints.values.sm) return setSize('sm');
      if(window.innerWidth>breakpoints.values.xs) return setSize('xs');
    }
    window.addEventListener('resize',resize);
    resize();
    return ()=>{
      window.removeEventListener('resize',resize);
    }
  },[setWidth]);
  const localRoutes=[
    <Route key='projet' path="/projet/:slug" element={<Projet/>}/>,
    <Route key='catalogue' path="/catalogue" element={<Catalogue/>}/>,
    <Route key='catalogue-projet' path="/catalogue/:slug" element={<Projet catalogue/>}/>,
    <Route key='contact' path="/contact" element={<Contact/>}/>,
    <Route key='equipe' path="/equipe" element={<Equipe/>}/>,
    <Route key='mentions' path="/mentions" element={<Mentions/>}/>,
    <Route key='une' path="/" element={<Une/>}/>,
    <Route key='preview' path="/preview" element={<Preview/>}/>,
  ];
  return (
      <ThemeProvider theme={theme}>
        <Router>
          <SettingsProvider clientSystem={clientSystem} width={width}>
            <MainMenu/>
            <div className='page'>
              <Routes>
              {localRoutes}
              </Routes>
            </div>
            <Footer/>
            <ScrollToTop/>
          </SettingsProvider>
        </Router>
      </ThemeProvider>
  );
}

export default App;
