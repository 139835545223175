import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import './Logo.scss'
import { SettingsContext } from 'Providers/SettingsProvider';
import Conf from 'utils/Conf';

function Logo({from, variant, link=true}) {
  const { appState,lang } = useContext(SettingsContext);
  const { defaultBbpv } = appState;
  const logo=<div className={'logo'+(from ? ' logo-'+from : '')+(variant ? ' logo-'+variant : '')}>
  </div>;
  return link ? <NavLink to={from===defaultBbpv ? '/' : ( from==='bb' ? `${Conf.urlsBb[0]}?lang=${lang}` : `${Conf.urlsPv[0]}?lang=${lang}` )}>{logo}</NavLink> : logo;
}
export default Logo;
