import { gql } from '@apollo/client';
const queries={
  settings:gql`
    query Settings{
      languages {
        code
        name
      }
      interface {
        cle
        translations {
          languages_code {
            code
          }
          valeur
          html
        }
      }
      projets (filter: {status: { _eq:"Published"}}, sort: ["sort"], limit: -1) {
        id
      }
    }
  `,
  une:gql`
    query Une($catalogue: String!){
      projets (filter: {status: { _eq:"Published"}, une: {_eq:true}, catalogue:{_eq:$catalogue}}, sort: ["sort"], limit: -1) {
        id
        translations {
          languages_id {
            code
          }
          titre
          soustitre
        }
        slugs {
          slug
        }
        visuel_une {
          id
        }
      }
    }
  `,
  contact:gql`
    query Contact{
      contact {
        translations {
          languages_code {
            code
          }
          titre
          texte
          adresses
        }
      }
    }
  `,
  equipe:gql`
    query Equipe{
      equipe {
        translations {
          languages_code {
            code
          }
          titre
          texte
        }
      }
      equipe_pv {
        translations {
          languages_code {
            code
          }
          titre
          texte
        }
      }
    }
  `,
  mentions:gql`
    query Mentions{
      mentions {
        translations {
          languages_code {
            code
          }
          titre
          texte
        }
      }
    }
  `,
  catalogue:gql`
    query Catalogue{
      projets (filter: {status: { _eq:"Published"}}, sort: ["sort"], limit: -1) {
        id
        auteur
        date
        translations {
          languages_id {
            code
          }
          titre
          soustitre
          pitch
          meta
        }
        type {
          translations {
            languages_code {
              code
            }
            nom
          }
        }
        slugs {
          slug
        }
        visuel {
          id
        }
      }
    }
  `,
  projet:gql`
    query Projet($slug: String!,$catalogue: String!){
      projets (filter: {slugs: {slug: {_eq : $slug}}, status: { _eq:"Published"}}) {
        id
        auteur
        date
        catalogue
        visuel {
          id
        }
        translations {
          languages_id {
            code
          }
          titre
          soustitre
          pitch
          meta
        }
        type {
          translations {
            languages_code {
              code
            }
            nom
          }
        }
        slugs {
          slug
        }
        diaporama(sort: ["sort"]) {
          directus_files_id {
            id
            type
          }
        }
      }
      listeCatalogue: projets (filter: {status: { _eq:"Published"}}, sort: ["sort"], limit: -1) {
        id
        auteur
        date
        translations {
          languages_id {
            code
          }
          titre
          soustitre
          pitch
          meta
        }
        type {
          translations {
            languages_code {
              code
            }
            nom
          }
        }
        slugs {
          slug
        }
      }
      listeUne: projets (filter: {status: { _eq:"Published"}, une: {_eq:true}, catalogue:{_eq:$catalogue}}, sort: ["sort"], limit: -1) {
        id
        translations {
          languages_id {
            code
          }
          titre
          soustitre
        }
        slugs {
          slug
        }
        visuel_une {
          id
        }
      }
    }
  `,
  projetPreview:gql`
    query projetPreview($id: GraphQLStringOrFloat!){
      projets (filter: {id: {_eq : $id}}) {
        id
        auteur
        date
        catalogue
        translations {
          id
          languages_id {
            code
          }
          titre
          soustitre
          pitch
          meta
        }
        type {
          translations {
            languages_code {
              code
            }
            nom
          }
        }
        slugs {
          slug
        }
        diaporama {
          id
          sort
          directus_files_id {
            id
            type
          }
        }
      }
    }
  `,
}
export default queries;
